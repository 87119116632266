import React, { useReducer } from 'react'
import { UserProvider } from './Context'
import Reducer from './Reducer'

//Languaje
import { PT_BR } from '../translations/pt_BR'
import { ES_CL } from '../translations/es_CL'
import { EN_US } from '../translations/en_US'
import { ES_ES } from '../translations/es_ES'
import { ES_CO } from '../translations/es_CO'

//Calendar Languaje
import pt from 'date-fns/locale/pt'
import es from 'date-fns/locale/es'
import en from 'date-fns/locale/en-US'

import {
  DUMMY_URL_CL,
  LOCALE_URL,
  SET_ADDRESS_INPUTS,
  SET_COMMUNE,
  SET_COUNTRIES,
  SET_BUTTON_STEP_TICKET,
  SET_COUNTRY,
  SET_LANGUAGE_CHANGED,
  SET_LOCALES,
  SET_LOG_STATUS,
  SET_PASSENGER_INPUTS,
  SET_PLACES,
  SET_QUANTITY,
  SET_ROUTES_PASSENGER_INPUTS,
  SET_SEARCH,
  SET_TRANSLATE,
  SET_USER_DATA,
  SET_CLEAR_CONTEXT,
  SET_DISCOUNT,
} from './Constants'

const languaje = {
  pt_BR: PT_BR(),
  es_CL: ES_CL(),
  en_US: EN_US(),
  es_ES: ES_ES(),
  es_CO: ES_CO(),
}

const Calendarlanguaje = {
  pt_BR: pt,
  es_CL: es,
  en_US: en,
  es_ES: es,
  es_CO: es,
}

const State = (props) => {
  const initialState = {
    buttonStepTicket: false,
    addressInputs: {},
    communeCode: '',
    countries: [],
    country: 'CO',
    isChanged: false,
    locales: [],
    logStatus: false,
    passengerInputs: {},
    placesByEvent: [],
    quantity: {},
    routesPassengerInputs: {},
    search: '',
    translate: '',
    userData: {},
    discount: [],
  }

  const [state, dispatch] = useReducer(Reducer, initialState)

  //-------------------------COUNTRY--------------------------//
  // Set Default Country
  const setCountry = (country) => {
    dispatch({ type: SET_COUNTRY, payload: country })
  }

  // Get Default Country
  const getCountry = () => {
    return state.country
  }
  //------------------------------------------------------------//

  //--------------------------TRANSLATE--------------------------//
  // Set Translate List
  const setLanguage = (translate) => {
    dispatch({ type: SET_TRANSLATE, payload: translate })
    dispatch({ type: SET_LANGUAGE_CHANGED, payload: true })
  }

  // Get Actual Language
  const getLanguage = () => {
    return state.translate
  }

  // Get Translate List
  const t = (text) => {
    if (state.translate === '') {
      // state.translate = "CL";
      state.translate = 'es_CO'
    }
    let translate = languaje[state.translate][text]
    return translate
  }

  const getLanguageChanged = () => {
    return state.isChanged
  }
  //------------------------------------------------------------//

  //---------------------PLACES BY EVENT------------------------//
  // Set Translate List
  const setPlacesByEvent = (places) => {
    dispatch({ type: SET_PLACES, payload: places })
  }

  // Get Actual Language
  const getPlacesByEvent = () => {
    return state.placesByEvent
  }
  //------------------------------------------------------------//

  //---------------------QUANTITY------------------------//
  // Set Translate List
  const setQuantity = (quantity) => {
    dispatch({ type: SET_QUANTITY, payload: quantity })
  }

  // Get Actual Language
  const getQuantity = () => {
    return state.quantity
  }
  //------------------------------------------------------------//

  //---------------------USER DATA------------------------//
  // Set Translate List
  const setUserData = (userData) => {
    dispatch({ type: SET_USER_DATA, payload: userData })
  }

  // Get Actual Language
  const getUserData = () => {
    return state.userData
  }
  //------------------------------------------------------------//

  //---------------------COMMUNE------------------------//
  // Set Translate List
  const setCommuneCode = (communeCode) => {
    dispatch({ type: SET_COMMUNE, payload: communeCode })
  }

  // Get Actual Language
  const getCommuneCode = () => {
    return state.communeCode
  }
  //------------------------------------------------------------//

  //---------------------BUTTON STEP TICKET------------------------//
  // Set Button Step Ticket
  const setButtonStepTicket = (action) => {
    dispatch({ type: SET_BUTTON_STEP_TICKET, payload: action })
  }

  // Get Button Step Ticket
  const getButtonStepTicket = () => {
    return state.buttonStepTicket
  }

  //---------------------ADDRESS INPUTS------------------------//
  // Set Address Inputs
  const setContextAddressInputs = (addressInputs) => {
    dispatch({ type: SET_ADDRESS_INPUTS, payload: addressInputs })
  }

  // Get Address Inputs
  const getContextAddressInputs = () => {
    return state.addressInputs
  }
  //------------------------------------------------------------//

  //---------------------PASSENGER INPUTS------------------------//
  // Set Passenger Inputs
  const setContextPassengerInputs = (passengerInputs) => {
    dispatch({ type: SET_PASSENGER_INPUTS, payload: passengerInputs })
  }

  // Get Passenger Inputs
  const getContextPassengerInputs = () => {
    return state.passengerInputs
  }
  //------------------------------------------------------------//

  //---------------------COUNTRIES------------------------//
  // Set Countries
  const setCountries = (countries) => {
    dispatch({ type: SET_COUNTRIES, payload: countries })
  }

  // Get Countries
  const getCountries = () => {
    return state.countries
  }
  //------------------------------------------------------------//

  //---------------------LOCALES------------------------//
  // Set Countries
  const setLocales = (locales) => {
    dispatch({ type: SET_LOCALES, payload: locales })
  }

  // Get Countries
  const getLocales = () => {
    return state.locales
  }
  //------------------------------------------------------------//

  //------------------ROUTES PASSENGER INPUTS---------------------//
  // Set Routes Passenger Inputs
  const setRoutesPassengerInputs = (routesPassengerInputs) => {
    dispatch({
      type: SET_ROUTES_PASSENGER_INPUTS,
      payload: routesPassengerInputs,
    })
  }

  // Get Routes Passenger Inputs
  const getRoutesPassengerInputs = () => {
    return state.routesPassengerInputs
  }
  //------------------------------------------------------------//

  //-------------------------DEFAULT URL--------------------------//
  // Get Default URL
  const getDefaultUrl = () => {
    let currentUrl = window.location.hostname

    if (currentUrl.includes(LOCALE_URL)) {
      currentUrl = DUMMY_URL_CL
    }
    return currentUrl
  }
  //------------------------------------------------------------//

  //----------------------CALENDAR LANGUAGE----------------------//
  // Get Default URL
  const getCalendarLanguage = () => {
    if (state.translate === '') {
      state.translate = 'es_CL'
    }
    let translate = Calendarlanguaje[state.translate]
    return translate
  }
  //------------------------------------------------------------//

  //----------------------CLEAR STATES----------------------//
  // Set states to empty
  const clearContext = () => {
    dispatch({ type: SET_CLEAR_CONTEXT })
  }
  //------------------------------------------------------------//

  //----------------------SEARCH STATES----------------------//
  const setSearch = (param) => {
    dispatch({ type: SET_SEARCH, payload: param })
  }

  const getSearch = () => {
    return state.search
  }
  //------------------------------------------------------------//

  const setLogStatus = (logStatus) => {
    dispatch({ type: SET_LOG_STATUS, payload: logStatus })
  }

  const getLogStatus = () => {
    return state.logStatus
  }

  //------------------------------------------------------------//
  const setDiscount = (discounts) => {
    dispatch({type: SET_DISCOUNT, payload: discounts})
  }
  const getDiscount = () => {
    return state.discount
  }

  return (
    <UserProvider
      value={{
        getButtonStepTicket,
        getCalendarLanguage,
        getCommuneCode,
        getContextAddressInputs,
        getContextPassengerInputs,
        getCountries,
        getCountry,
        getDefaultUrl,
        getLanguage,
        getLanguageChanged,
        getLocales,
        getLogStatus,
        getPlacesByEvent,
        getQuantity,
        getRoutesPassengerInputs,
        getUserData,
        setButtonStepTicket,
        setCommuneCode,
        setContextAddressInputs,
        setContextPassengerInputs,
        setCountries,
        setCountry,
        setLanguage,
        setLocales,
        setLogStatus,
        setPlacesByEvent,
        setQuantity,
        setRoutesPassengerInputs,
        setUserData,
        t,
        clearContext,
        setSearch,
        getSearch,
        setDiscount,
        getDiscount,
      }}
    >
      {props.children}
    </UserProvider>
  )
}

export default State
